$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;

$background-color: #FFFFFF;
$light-background-color: #F8F7F9;
$border-color: #E8E7EA;

$text-color: #222222;
$light-text-color: #9C9B9D;

$primary: #FF206E;
$secondary: #0C0F0A;
