.fm-verify-email {

}

.fm-verify-email .fm-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.fm-verify-email .fm-loading {
  min-height: 300px;
  min-height: 70vh;
}

.fm-verify-email-title {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  line-height: 40px;
  margin: 40px 10px;
}

.fm-verify-email-text {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin: 10px 0;
}

.fm-verify-email-button {
  background: $primary;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  padding: 15px 35px;
  line-height: 20px;
  border-radius: 25px;
  display: inline-block;
  margin: 60px 0;
}

.fm-verify-email-button:hover {
  background: #e2165d;
}
