.fm-top-bar {
  border-bottom: 1px solid $border-color;
}

.fm-top-bar-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction:  row;
  align-items: center;
  padding: 20px 0;
  height: 80px;
  justify-content: center;
}

.fm-top-bar-brand {
  color: #FF206E;
  font-size: 24px;
  line-height: 37px;
  padding: 3px 10px 0 10px;
  display: inline-block;
  cursor: pointer;
  transition: ease all 0.2s;
}
