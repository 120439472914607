.fm-intoxication {
  width: 100%;
  width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  height: 100%;
  height: 100vh;
  background: #000;
}

/* OVERLAYS */

.fm-intoxication-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}

.fm-intoxication-overlay-container {
  width: 100%;
  max-width: 500px;
  padding: 50px;
  margin: 0 auto;
}

.fm-intoxication-title {
  font-size: 30px;
  color: #fff;
  font-weight: 800;
  text-align: center;
  line-height: 40px;
  margin-bottom: 30px;
}

.fm-intoxication-description {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 30px;
  margin-bottom: 20px;
}

.fm-intoxication-button-play {
  text-align: center;
  width: 200px;
  border: 4px solid #fff;
  background: #44bd32;
  font-size: 20px;
  font-weight: 800;
  color: #fff;
  padding: 10px;
  margin: 50px auto;
  display: block;
  cursor: pointer;
}

.fm-intoxication-button-cancel {
  text-align: center;
  width: 200px;
  border: 4px solid #fff;
  background: #e84118;
  font-size: 20px;
  font-weight: 800;
  color: #fff;
  padding: 10px;
  margin: 50px auto;
  display: block;
  cursor: pointer;
}

.fm-intoxication-button-try {
  text-align: center;
  width: 200px;
  border: 4px solid #fff;
  background: #0097e6;
  font-size: 20px;
  font-weight: 800;
  color: #fff;
  padding: 10px;
  margin: 50px auto;
  display: block;
  cursor: pointer;
}

/* PLAYER */

.fm-intoxication-player {
  width: 100%;
  width: 100vw;
  height: 100%;
  height: 100vh;
  background: #7CB342;
  max-width: 500px;
  margin: 0 auto;
  overflow: hidden;
}

.fm-intoxication-banner {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 30px;
  margin-bottom: 20px;
  text-align: center;
  display: block;
  padding: 80px 20px 0 20px;
}

.fm-intoxication-game {
  background-color: #80D8FF;
  width: 100%;
  height: 500px;
  background-image: url('https://storage.googleapis.com/flamingo-static/images/app/intoxication/background.png');
  background-size: 1170px;
  background-repeat: repeat-x;
  background-position: 0 100%;
  position: relative;

  animation: drive 120s linear infinite;
  -moz-animation: drive 120s linear infinite;
  -webkit-animation: drive 120s linear infinite;
  -ms-animation: drive 120s linear infinite;
  -o-animation: drive 120s linear infinite;
}

.fm-intoxication-grass {
  background: #7CB342;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
}

.fm-intoxication-rider {
  background: url('https://storage.googleapis.com/flamingo-static/images/app/intoxication/scooter.png');
  background-position: 0px 0px;
  background-size: 100px;
  height: 110px;
  width: 110px;
  background-repeat: no-repeat;
  position: absolute;
  left: 10px;
  bottom: 60px;

  animation: jiggle 5s linear infinite;
  -moz-animation: jiggle 5s linear infinite;
  -webkit-animation: jiggle 5s linear infinite;
  -ms-animation: jiggle 5s linear infinite;
  -o-animation: jiggle 5s linear infinite;
}

.fm-intoxication-stop {
  background: url('https://storage.googleapis.com/flamingo-static/images/app/intoxication/sign-stop.png');
  background-position: 0px 0px;
  background-size: 43px 100px;
  height: 100px;
  width: 43px;
  background-repeat: no-repeat;
  position: absolute;
  right: -50px;
  bottom: 35px;
}

.fm-intoxication-speed {
  background: url('https://storage.googleapis.com/flamingo-static/images/app/intoxication/sign-speed.png');
  background-position: 0px 0px;
  background-size: 43px 100px;
  height: 100px;
  width: 43px;
  background-repeat: no-repeat;
  position: absolute;
  right: -50px;
  bottom: 35px;
}

.fm-intoxication-sign-move {
  animation: sign 3.5s linear infinite;
  -moz-animation: sign 3.5s linear infinite;
  -webkit-animation: sign 3.5s linear infinite;
  -ms-animation: sign 3.5s linear infinite;
  -o-animation: sign 3.5s linear infinite;
}

/* ANIMATIONS */

@keyframes drive {
  0% { background-position: 4680px 100%; }
  100% { background-position: 0 100%; }
}
@-moz-keyframes drive {
  0% { background-position: 4680px 100%; }
  100% { background-position: 0 100%; }
}
@-webkit-keyframes drive {
  0% { background-position: 4680px 100%; }
  100% { background-position: 0 100%; }
}
@-ms-keyframes drive {
  0% { background-position: 4680px 100%; }
  100% { background-position: 0 100%; }
}
@-o-keyframes drive {
  0% { background-position: 4680px 100%; }
  100% { background-position: 0 100%; }
}

@keyframes sign {
  0% { right: -50px; }
  100% { right: 500px; }
}
@-moz-keyframes sign {
  0% { right: -50px; }
  100% { right: 500px; }
}
@-webkit-keyframes sign {
  0% { right: -50px; }
  100% { right: 500px; }
}
@-ms-keyframes sign {
  0% { right: -50px; }
  100% { right: 500px; }
}
@-o-keyframes sign {
  0% { right: -50px; }
  100% { right: 500px; }
}

@keyframes jiggle {
  0% { background-position: 0 0; }
  25% { background-position: 10px 0px; }
  50% { background-position: 0 10px; }
  75% { background-position: 10px 10px; }
  100% { background-position: 0 0; }
}
@-moz-keyframes jiggle {
  0% { background-position: 0 0; }
  25% { background-position: 10px 0px; }
  50% { background-position: 0 10px; }
  75% { background-position: 10px 10px; }
  100% { background-position: 0 0; }
}
@-webkit-keyframes jiggle {
  0% { background-position: 0 0; }
  25% { background-position: 10px 0px; }
  50% { background-position: 0 10px; }
  75% { background-position: 10px 10px; }
  100% { background-position: 0 0; }
}
@-ms-keyframes jiggle {
  0% { background-position: 0 0; }
  25% { background-position: 10px 0px; }
  50% { background-position: 0 10px; }
  75% { background-position: 10px 10px; }
  100% { background-position: 0 0; }
}
@-o-keyframes jiggle {
  0% { background-position: 0 0; }
  25% { background-position: 10px 0px; }
  50% { background-position: 0 10px; }
  75% { background-position: 10px 10px; }
  100% { background-position: 0 0; }
}
